import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import Modal from "react-modal";
import CloseIcon from '../svg/CloseIcon';

Modal.setAppElement("#portal");
const customStyles = {
  content: {
    inset: '28px'
  },
};

export default function TeamMember({ member, index }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div key={index} className={"w-full md:w-1/2 lg:w-1/3 xl:w-1/4 p-5 cursor-pointer team"}>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel={member?.name}
        className={"modal"}
        style={customStyles}
      >
        <div className={"w-full"}>
          <div onClick={toggleModal} className={"fixed top-0 right-0 px-2 py-1 z-50 cursor-pointer"}><CloseIcon height={30} width={30} fill={'#FFFFFF'}/></div>
          <GatsbyImage
            image={member?.image?.childImageSharp?.gatsbyImageData}
            alt={member?.alt}
            className={"w-full"}/>
          <div className={"relative -mt-9 shadow-xl"}>
            <div className={"absolute modal-team-name text-xl font-semibold xl:text-md shadow-xl"}>
              {member?.name}
            </div>
            <div className={"absolute modal-team-title text-normal shadow-xl"}>
              {member?.title}
            </div>
          </div>
          <div className={"mt-24 px-5 text-sm text-gray-600 mb-5"}>
            {member?.description.map((text, index) => (
              <p key={index}>{text}</p>
            ))}
          </div>
        </div>
      </Modal>
      <div onClick={() => setIsOpen(true)}>
        <div className={"rounded-full shadow-2xl zoom-on-hover border-4 border-gray-300 hover:border-primary-default relative"}>
          <GatsbyImage
            image={member?.image?.childImageSharp?.gatsbyImageData}
            alt={member?.alt}
            className={"rounded-full team-image transition-none"}/>
          <div className={"absolute team-name text-sm xl:text-md shadow-xl"}>
            {member?.name}
          </div>
          <div className={"absolute team-title text-xs shadow-xl"}>
            {member?.title}
          </div>
        </div>
      </div>
    </div>
  );
};
